import React from 'react';
import { graphql } from 'gatsby';
import withRoot from '../withRoot';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MetaPanel from '../components/meta-panel';
import Page from '../components/page';
import { RichText } from 'prismic-reactjs';

class Pages extends React.Component {
    getPageName = (page) => {
        if (page.name) {
            return RichText.asText(page.name);
        }
        return ``;
    };

    getDescription = (page) => {
        if (page.description) {
            return RichText.asText(page.description);
        }
        return RichText.asText(page.name);
    };

    render() {
        const { data } = this.props;
        const page = data.prismic.page;
        const name = this.getPageName(page);

        return (
            <Layout>
                <SEO title={name} description={this.getDescription(page)} />
                <MetaPanel title={name} />
                <Page>{page}</Page>
            </Layout>
        );
    }
}

export default withRoot(Pages);

export const pageQuery = graphql`
    query PagesBySlug($uid: String!) {
        prismic {
            page(uid: $uid, lang: "en-gb") {
                name
                description
                _meta {
                    uid
                }
                body {
                    __typename
                    ... on PRISMIC_PageBodyHeading {
                        type
                        primary {
                            heading
                        }
                    }
                    ... on PRISMIC_PageBodyParagraph_block {
                        type
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_PageBodyCode_block {
                        type
                        primary {
                            language
                            embed_link
                            code
                        }
                    }
                    ... on PRISMIC_PageBodyMessage_block {
                        type
                        primary {
                            message_type
                            message_title
                            message_content
                        }
                    }
                    ... on PRISMIC_PageBodyImage_block {
                        type
                        primary {
                            image
                            image_title
                            caption
                        }
                    }
                    ... on PRISMIC_PageBodyQuote_block {
                        type
                        primary {
                            quote
                            author
                            link {
                                ... on PRISMIC__ExternalLink {
                                    url
                                }
                            }
                        }
                    }
                    ... on PRISMIC_PageBodyEmbed_block {
                        type
                        primary {
                            embed
                        }
                    }
                }
                featured_realms {
                    realm {
                        __typename
                        ... on PRISMIC_Realm {
                            name
                            icon
                            coming_soon
                            _meta {
                                uid
                            }
                        }
                    }
                }
                featured_quests {
                    quest {
                        __typename
                        ... on PRISMIC_Quest {
                            name
                            description
                            icon
                            difficulty
                            coming_soon
                            magento_versions {
                                magento_version
                            }
                            _meta {
                                uid
                                lastPublicationDate
                            }
                        }
                    }
                }
                read_next {
                    __typename
                    ... on PRISMIC_Page {
                        name
                        _meta {
                            uid
                        }
                    }
                    ... on PRISMIC_Quest {
                        name
                        _meta {
                            uid
                        }
                    }
                    ... on PRISMIC_Realm {
                        name
                        _meta {
                            uid
                        }
                    }
                }
                read_next_text
            }
        }
    }
`;
